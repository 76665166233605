import { gql } from "@apollo/client/core";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";
import { MutationRemoveResponsibleArgs } from "@/graphql/types";
import { deletePaginationCache } from "@/graphql/utils/pagination";
import { useConfirm } from "primevue/useconfirm";
import { ref } from "vue";
import { ActiveActivity } from "@/plugins/i18n";
import { setActiveActivity } from "@/graphql/session";
import { useSecurityCounter } from "@/graphql/activity/secutrity-counter";

type RemoveResponsibleData = {
  removeResponsible: string;
};
const REMOVE_RESPONSIBLE = gql`
  mutation RemoveResponsible($input: RemoveResponsibleInput!) {
    removeResponsible(input: $input)
  }
`;
export const useRemoveResponsible = () => {
  const toast = useToast();
  const { t } = useI18n();
  const confirm = useConfirm();
  const leaving = ref(false);
  const { counter, dialog, preventDelete } = useSecurityCounter();
  const {
    mutate,
    loading: removeLoading,
    onDone,
  } = useMutation<RemoveResponsibleData, MutationRemoveResponsibleArgs>(
    REMOVE_RESPONSIBLE,
    {
      update(cache, { data }) {
        if (data?.removeResponsible) {
          const id = data.removeResponsible;
          cache.modify({
            fields: {
              activityResponsible(existingRef: any, { readField }) {
                return existingRef.filter(
                  (eRef) => readField("id", eRef) !== id
                );
              },
              invitations(existing: any, { toReference, readField }) {
                return deletePaginationCache(
                  [id],
                  existing,
                  readField,
                  toReference
                );
              },
              userResponsible(existingRef: any[], { readField }) {
                if (leaving.value) {
                  setActiveActivity(new ActiveActivity());
                  return existingRef.filter(
                    (eRef: any) => readField("id", eRef) != id
                  );
                }
                return existingRef;
              },
            },
          });
        }
      },
    }
  );
  onDone(({ data }) => {
    const msg = leaving.value ? "leave" : "remove";
    toast.add({
      severity: "success",
      summary: t(`responsible.${msg}.title`),
      detail: t(
        `responsible.${msg}.${data?.removeResponsible ? "success" : "failed"}`
      ),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  function submitRemove(event: any, id: string, leave = true) {
    leaving.value = leave;
    confirm.require({
      target: event.currentTarget,
      message: t(
        leaving.value ? "responsible.leave.confirm" : "delete.confirm"
      ),
      icon: "pi pi-exclamation-triangle",
      rejectLabel: t("no"),
      acceptLabel: t("yes"),
      acceptClass: "p-button-warning",
      accept: () => {
        void mutate({ input: { id, leave } });
      },
    });
  }

  function confirmLeaving(id: string) {
    leaving.value = true;
    void mutate({ input: { id, leave: true } });
  }

  return {
    removeLoading,
    submitRemove,
    dialog,
    counter,
    preventDelete,
    confirmLeaving,
  };
};
