import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";

export const useSecurityCounter = () => {
  const { t } = useI18n();
  const confirm = useConfirm();
  const counter = ref(0);
  const dialog = reactive({
    checked: false,
    show: false,
  });
  function preventDelete(withConf = true) {
    launchCounter();
    if (withConf) {
      const data = {
        message: t("activity.delete.warning"),
        header: t("delete.title"),
        group: "dialog",
        accept: () => (dialog.show = true),
        icon: "pi pi-exclamation-triangle",
        rejectLabel: t("cancel"),
        acceptLabel: t("continue"),
        acceptClass: "p-button-warning",
      };
      confirm.require(data);
    } else dialog.show = true;
  }

  function launchCounter() {
    counter.value = 60;
    const timer = setInterval(() => {
      counter.value += -1;
      if (counter.value <= 0) {
        counter.value = 0;
        clearInterval(timer);
      }
    }, 1000);
  }

  function resetActivity(callback: () => void) {
    const data = {
      header: t("activity.reset.title"),
      message: t("activity.reset.alert"),
      group: "dialog",
      accept: () => callback(),
      icon: "pi pi-exclamation-triangle",
      rejectLabel: t("cancel"),
      acceptLabel: t("continue"),
      acceptClass: "p-button-warning",
    };
    confirm.require(data);
  }

  return { counter, dialog, preventDelete, resetActivity, launchCounter };
};
