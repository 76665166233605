import { MutationChangeResponsibleRoleArgs, Responsible } from "../types";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";

type Data = {
  changeResponsibleRole: Responsible;
};

const MUTATION = gql`
  mutation ChangeResponsibleRole($input: ChangeResponsibleRoleInput!) {
    changeResponsibleRole(input: $input) {
      id
      role {
        id
        name
      }
    }
  }
`;
export const useChangeResponsibleRole = () => {
  const { t } = useI18n();
  const toast = useToast();
  const changeRoleForm = reactive({
    id: "",
    visible: false,
    role: null,
    name: "",
  });
  function openChangeRole(res: Responsible) {
    changeRoleForm.id = res.id;
    changeRoleForm.role = res.roleId;
    changeRoleForm.name = res.user.name || res.user.email;
    changeRoleForm.visible = true;
  }
  const {
    loading: loadingChange,
    mutate,
    onDone,
  } = useMutation<Data, MutationChangeResponsibleRoleArgs>(MUTATION);

  onDone(({ data }) => {
    if (!data) {
      toast.add({
        severity: "error",
        summary: t("responsible.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else {
      changeRoleForm.visible = false;
      toast.add({
        severity: "success",
        summary: t("update.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });

  function changeRole() {
    void mutate({
      input: { id: changeRoleForm.id, role: changeRoleForm.role },
    });
  }
  return {
    loadingChange,
    changeRole,
    openChangeRole,
    changeRoleForm,
  };
};
